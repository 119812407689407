/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "flatpickr/dist/flatpickr.min.css";
@import "flatpickr/dist/plugins/monthSelect/style.css";
@import 'assets/scss/vars.scss';
@import 'assets/scss/flatpickr_override.scss';
:root{
  background-color: rgb(224 231 255); //override daisyUI theme background
}
body::-webkit-scrollbar-track {
  background-color: rgb(0, 0, 0);
}
body {
  font-family: 'Poppins';
  font-size: 22px;
}
//The scrollbar thumb
a{
  color: inherit ;
  transition: color 0.3s ease;
}

a:hover{
  color:$accent-color-1;
}

.c-bg-blue{
  background-color: $headdings-blue;
}
.c-border-blue{
  border-color: $headdings-blue;
}
.c-text-blue{
  color: $headdings-blue;
}
.c-accent-1{
  color:$accent-color-1;
}
.c-accent-2{
  color:$accent-color-2;
}
.c-b-accent-2{
  background-color:$accent-color-2;
}
.c-accent-3{
  color:$accent-color-3;
}
.c-b-accent-3{
  background-color:$accent-color-3;
}
.c-accent-4{
  color:$accent-color-4;
}
.c-b-accent-4{
  background-color:$accent-color-4;
}

.c-b-accent-1{
  background-color:$accent-color-1;
}
.c-b-accent-2{
  background-color:$accent-color-2;
}
.c-b-accent-3{
  background-color:$accent-color-3;
}

.c-b-gray{
  background-color:$accent-color-5;
}

.border-separate {
  border-collapse: separate;
}


/* Apply styles to all scrollbars */
*::-webkit-scrollbar,
div::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  margin: 8vh;
  width: .35vw !important;
  height: .35vw !important; /* Ensure horizontal scrollbar has a height */
  cursor: grab !important;
}

/* Customize the scrollbar thumb (the draggable part) */
*::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  // background-color: #5C78FF !important;
  background-color: lightgray !important;
  margin: 1vh  !important; /* Ensure margin is set for both block and inline directions */
  border-radius: 100vw !important;
}

/* Customize the scrollbar track (the background) */
body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track {
  background-color: inherit !important;
  margin: 2vh   !important; /* Ensure margin is set for both block and inline directions */
  border-radius: 100vw !important;
}

/* Specific styles for textarea scrollbars, if needed */
textarea::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-clip: padding-box !important;
}

 textarea::-webkit-scrollbar-track {
   margin: 2vh  !important; /* Ensure margin is set for both block and inline directions */
  // border-radius: 10px !important;
//   width: 50px !important;
 }