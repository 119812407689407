

$accent-color-1: #5D78FF;
$accent-color-2: #FD397A;
$accent-color-3: #1DC9B7;
$accent-color-4: #FFB822;
$accent-color-5: #8693A6;
$headdings-blue: #5C78FF;



