.flatpickr-innerContainer {
  border: none !important;
}

.flatpickr-current-month {
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 23px;
  letter-spacing: 0em;
  cursor: pointer;
  &:hover {
    background-color: #E4E3E3;
  }
}

.flatpickr-current-month span.cur-year {
  font-weight: 700 !important;
  cursor: pointer !important;
}

.flatpickr-current-month span.cur-month:hover {
  background-color: #E4E3E3 !important;
}

.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
  top: -5px !important;
}

.flatpickr-monthSelect-month {
  color: #777171 !important;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  text-transform: uppercase !important;
  padding: 6px 0px !important;
  margin: 4px 0px !important;
}

.flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-month.endRange,
.flatpickr-monthSelect-month.startRange {
  background-color: #5C78FF !important;
  color: white !important;
}

.flatpickr-monthSelect-month.selected {
  border-radius: 50px 0px 0px 50px;
	&.inRange, &.outRange {
		border-radius: 0px;
		border-color: #e6e6e6;
		color: #777171 !important;
	}
	&.outRange {
		background: none;
		background-color: transparent !important;
		border: 1px solid transparent !important;
	}
}

.flatpickr-monthSelect-month.selected.endRange {
  border-radius: 0px 50px 50px 0px;
}
.flatpickr-monthSelect-month.selected.endRange.startRange {
  border-radius: 50px ;
}

.flatpickr-monthSelect-month.inRange{
  background: #E2E7FF !important;
  box-shadow: none !important;
}

.flatpickr-monthSelect-month.inEndRange {
	background: #E2E7FF !important;
	box-shadow: none !important;
	border-color: #e6e6e6;
	color: #777171 !important;
	border-radius: 0px;
}


.flatpickr-monthSelect-month.today {
  border: none !important;
}

.flatpickr-current-month .arrowUp,
.flatpickr-current-month .arrowDown {
  display: none !important;
}

.flatpickr-current-month .numInputWrapper {
  &:hover {background: none !important;}
	.numInput.cur-year { cursor: pointer !important;}
}